import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "wrapper",
    "menuList",
    "menuButton",
    "notice",
    "noticeButton"
  ]

  connect() {
  }

  toggleMenu(event) {
    this.wrapperTarget.classList.toggle('menu--open');
    this.menuListTarget.classList.toggle('hidden');
    this.menuButtonTarget.querySelectorAll('svg').forEach(function(item, i){
      item.classList.toggle('hidden');
    });
  }

  hideNotice(event) {
    this.noticeTarget.classList.add('hidden');
  }

  anchorLink(event) {

    let url = new URL(event.target.href)

    if( window.location.pathname === url.pathname ) {
      console.log('hello')
      this.toggleMenu(event);
    }

  }

}
